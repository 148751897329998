import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ExportInput } from '../../shared/models/dto/devices';
import { ExportSetting, ExportSettingValue } from '../../shared/models/dto/export-setting';
import { NameValue } from '../../shared/models/dto/general';
import AuthStore from '../../stores/auth-store';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class SaveExportDialogComponent extends AuthComponentBase {
    @Prop({ default: false }) readonly show: boolean;
    @Prop({ default: null }) readonly exportInput: ExportInput;
    @Prop({ default: null }) readonly selectedExportSetting: ExportSetting;

    exportSetting: ExportSetting = {
        displayName: null,
        exportSettingValues: [],
        userId: null
    };

    refs = this.$refs as any;
    errors: NameValue[] = [];
    valid: boolean = true;
    formTitle: string = '';

    @Watch('show', { immediate: true })
    async onShowChanged() {
        if (this.show) {
            this.formTitle = this.t('SaveExport');

            this.exportSetting = {
                displayName: '',
                exportSettingValues: new Array<ExportSettingValue>(),
                userId: AuthStore.getUser().id
            };

            if (this.selectedExportSetting) {
                this.exportSetting.displayName = this.selectedExportSetting.displayName;
            } else {
                this.refs.form.reset();
            }

            this.exportInput.deviceSensorInputs.forEach(x => {
                x.sensorIds.forEach((sensorId) => {
                    this.exportSetting.exportSettingValues.push({
                        sensorId: sensorId,
                        deviceId: x.deviceId
                    });
                });                
            });
        }
    }

    edit() {
        if (this.refs.form.validate()) {
            this.errors = [];

            this.exportSetting.id = this.selectedExportSetting.id;

            this.authService.put(`/api/exportSettings`, this.exportSetting).then((response) => {
                if (!response.isError) {
                    this.$emit('completed', true);
                } else {
                    response.errors.forEach(error => this.errors.push({
                        name: error.name,
                        value: error.value
                    }));
                }                
            });
        }
    }

    add() {
        if (this.refs.form.validate()) {
            this.errors = [];

            this.authService.post(`/api/exportSettings`, this.exportSetting).then((response) => {
                if (!response.isError) {
                    this.$emit('completed', true);
                } else {
                    response.errors.forEach(error => this.errors.push({
                        name: error.name,
                        value: error.value
                    }));                   
                }               
            });                        
        }
    }

    close() {
        this.$emit('completed', false);
    }
}