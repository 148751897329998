var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.t('Export')))]),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mr-3 pt-3",staticStyle:{"max-width":"350px"},attrs:{"clearable":"","hide-details":"","label":_vm.t('SelectExportSetting'),"items":_vm.exportSettings,"item-text":"displayName","item-value":"id"},on:{"change":_vm.onExportSettingChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-list-item',[_vm._v(" "+_vm._s(item.displayName)+" ")]),_c('v-spacer'),_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteExportSetting(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}},{key:"append-outer",fn:function(){return [_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.showSaveExport()}}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.t('SaveExport')))])])]},proxy:true}]),model:{value:(_vm.selectedExportSettingId),callback:function ($$v) {_vm.selectedExportSettingId=$$v},expression:"selectedExportSettingId"}}),_c('v-spacer'),(_vm.AppConsts.auth.isGranted('Permissions_Measurement_Read'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('date-range-selection',{attrs:{"show":true},on:{"date-change":_vm.onDateChange}})]}}],null,false,3949691927)}):_vm._e(),(_vm.AppConsts.auth.isGranted('Permissions_Measurement_Read'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-4",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.createExport()}}},on),[_c('v-icon',[_vm._v("mdi-export")])],1)]}}],null,false,1591076204)},[_c('span',[_vm._v(_vm._s(_vm.t('Export')))])]):_vm._e()],1),_c('error-message',{attrs:{"errors":_vm.errors}}),(_vm.selectedExportSettingId && _vm.exportInputChanged)?_c('div',[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.t('ExportInputChanged'))+" ")])],1):_vm._e(),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"custom-filter":_vm.executeSearch,"items":_vm.devices,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"search":_vm.search,"show-expand":"","expanded":_vm.expanded},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.search",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{attrs:{"single-line":"","hide-details":"","placeholder":_vm.t('Search'),"prepend-inner-icon":"mdi-magnify"},on:{"change":_vm.onSearchChanged},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}},{key:"header.checked",fn:function(){return [_c('td',{staticClass:"pa-0"},[_c('v-simple-checkbox',{attrs:{"ripple":false,"dense":"","indeterminate":_vm.indeterminate,"checked":_vm.allChecked},on:{"click":function($event){return _vm.selectAll()}},model:{value:(_vm.allChecked),callback:function ($$v) {_vm.allChecked=$$v},expression:"allChecked"}})],1)]},proxy:true},{key:"item.checked",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0"},[_c('v-simple-checkbox',{attrs:{"indeterminate":item.indeterminate,"ripple":false,"dense":"","checked":item.checked},on:{"click":function($event){return _vm.deviceSelected(item)}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"4"}},[_c('div',[_c('v-data-table',{staticClass:"ma-3",attrs:{"headers":_vm.subHeaders,"search":_vm.search,"items":item.checkableSensors,"dense":"","hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.checked",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticClass:"pl-7",attrs:{"dense":"","ripple":false,"checked":item.checked},on:{"click":function($event){return _vm.sensorSelected(item)}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})]}}],null,true)})],1)])]}}])})],1)],1),_c('save-export-dialog',{attrs:{"show":_vm.showSaveExportDialog,"export-input":_vm.exportInput,"selected-export-setting":_vm.selectedExportSetting},on:{"completed":_vm.closeSaveExportDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }