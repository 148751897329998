import ITSVueComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DeviceBasis, DeviceSensorInput, ExportInput } from '@/shared/models/dto/devices';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';
import { NameValue } from '../../shared/models/dto/general';
import { ODataFilterObject } from '../../shared/models/shared/odata-filter-object';
import { FilterOperator } from '../../shared/models/shared/filter-operator';
import { User } from '../../shared/models/dto/users';
import AuthStore from '../../stores/auth-store';
import { Sensor } from '../../shared/models/dto/sensor';
import { ExportSetting } from '../../shared/models/dto/export-setting';
import LanguageStore from '../../stores/language-store';

@Component({
    components: {
        dateRangeSelection: require('@/views/_components/date-range-selection/date-range-selection.vue').default,
        errorMessage: require('@/views/_components/error-message/error-message.vue').default,
        saveExportDialog: require('@/views/export/save-export-dialog.vue').default
    }
})
export default class ExportMeasurementsComponent extends ITSVueComponentBase {
    selectedLanguage: ILanguageDto = null;

    startDateFormatted: string = '';
    startDate: string = '';

    endDate: string = '';
    endDateFormatted: string = '';

    showStart: boolean = false;
    showEnd: boolean = false;

    showSaveExportDialog: boolean = false;

    search: string = '';

    expanded: any[] = [];

    get headers() {
        return [
            { value: 'checked', sortable: false, width: 40 },
            { text: this.t('Name'), value: 'name', filterOperator: FilterOperator.contains, inputType: 'string' },
            { value: 'search', width: 250, sortable: false }
        ];
    }

    get subHeaders() {
        return [
            { value: 'checked', sortable: false, width: 40 },
            { text: this.t('DisplayName'), value: 'displayName', filterOperator: FilterOperator.contains, inputType: 'string' },
            { text: this.t('Unit'), value: 'unit', filterOperator: FilterOperator.contains, inputType: 'string' }           
        ];
    }

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['name']
    };

    errors: NameValue[] = [];
    devices: DeviceCheckable[] = [];
    totalCount: number = 0;
    valid: boolean = false;
    loading: boolean = false;
    exportSettings: ExportSetting[] = [];
    selectedExportSettingId: number = null;
    selectedExportSetting: ExportSetting = null;

    exportInputChanged: boolean = false;

    exportInput: ExportInput = {
        deviceSensorInputs: [],
        end: null,
        start: null,
        languageCode: LanguageStore.getLanguage().languageCode,
    };

    indeterminate: boolean = false;
    allChecked: boolean = false;

    mounted() {
        this.exportInputChanged = false;
        this.totalCount = 0;
        this.selectedExportSettingId = null;
        this.selectedExportSetting = null;
        this.errors = [];
        this.devices = [];
        this.getItems();
    }

    deviceSelected(item: DeviceCheckable) {
        this.allChecked = false;
        this.indeterminate = false;
        item.indeterminate = false;

        if (this.search.length) {
            var filteredItems = item.checkableSensors.filter(x => x.displayName.toLowerCase().includes(this.search));

            filteredItems.forEach(x => x.checked = item.checked);
        }
        else {
            item.checkableSensors.forEach(x => {
                x.checked = item.checked;
            });    
        }

        const someChecked = this.devices.some(x => x.checked);
        if (someChecked) {
            const allChecked = this.devices.every(x => x.checked);
            this.allChecked = allChecked;
            this.indeterminate = !allChecked;
        }

        if (item.checked && this.expanded.indexOf(item) === -1) {
            this.expanded.push(item);
        }

        this.exportInputChanged = true;
    }

    onSearchChanged() {
        this.indeterminate = false;
        this.allChecked = false;

        this.devices.forEach(x => {
            x.checked = x.checkableSensors.every(p => p.checked);
            if (!x.checked) {
                x.indeterminate = x.checkableSensors.some(p => p.checked);
            }
        });

        this.allChecked = this.devices.every(x => x.checked);
        if (!this.allChecked) {
            
            this.indeterminate = this.devices.some(x => x.checked || x.indeterminate);
        }
    }

    executeSearch(value: string, search: string | null, item: DeviceCheckable): boolean {
        return item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.checkableSensors.some(x => x.displayName.toLowerCase().includes(search.toLowerCase()));
    }

    selectAll() {
        this.indeterminate = false;

        if (this.search.length) {
            var filteredItems = this.devices.filter(x => x.name.toLowerCase().includes(this.search) ||
                x.checkableSensors.some(p => p.displayName.toLowerCase().includes(this.search.toLowerCase())));

            filteredItems.forEach(x => x.checkableSensors
                .filter(p => p.displayName.toLowerCase()
                .includes(this.search.toLowerCase())).forEach(p => {
                p.checked = this.allChecked;
                x.checked = this.allChecked;
            }));
        }
        else {
            this.devices.forEach(x => {
                x.checkableSensors.forEach(p => {
                    p.checked = this.allChecked;
                    x.checked = this.allChecked;
                })

                if (!x.checked) {
                    x.indeterminate = x.checkableSensors.some(x => x.checked);
                }  
            });
        }       

        const someChecked = this.devices.some(x => x.checked);
        if (someChecked) {
            if (!this.allChecked) {
                this.indeterminate = true;
            }
        }

        this.expanded = this.devices;
        this.exportInputChanged = true;
    }

    sensorSelected(item: SensorCheckable) {
        var parent = this.devices.find(x => x.id == item.deviceId);
        parent.checked = false;
        parent.indeterminate = false;

        const someChecked = parent.checkableSensors.some(x => x.checked);

        if (someChecked) {
            const allChecked = parent.checkableSensors.every(x => x.checked);
            parent.checked = allChecked;
            parent.indeterminate = !allChecked;           
        }

        this.exportInputChanged = true;
    }

    onDateChange(startDate: string, endDate: string) {
        this.errors = [];
        this.startDate = startDate;
        this.endDate = endDate;

        if (this.startDate && this.endDate) {
            if (!this.isValidDateSelection()) {
                this.errors = [{
                    name: "InvalidDateRange",
                    value: this.t('InvalidDateRange')
                }];
            }
        }
    }

    async onExportSettingChange() {
        this.exportInputChanged = false;

        this.selectedExportSetting = null;

        this.devices.forEach(x => {
            x.checked = false;
            x.indeterminate = false;
            x.checkableSensors.forEach(p => p.checked = false);
        });

        this.allChecked = false;
        this.indeterminate = false;
        
        if (!this.selectedExportSettingId) {
            return;
        }

        await this.authService.get<ExportSetting>(`/api/exportsettings/${this.selectedExportSettingId}`).then((response) => {
            const exportSetting: ExportSetting = response.content;
            this.selectedExportSetting = exportSetting;

            this.devices.filter(x => exportSetting.exportSettingValues.some(p => p.deviceId === x.id)).forEach((x) => {
                x.checked = false;
                x.indeterminate = false;
                
                x.checkableSensors.forEach(p => {
                    p.checked = false;
                    if (exportSetting.exportSettingValues.some(d => d.sensorId == p.id)) {
                        p.checked = true;
                    }
                });

                const someChecked = x.checkableSensors.some(x => x.checked);
                if (someChecked) {
                    const allChecked = x.checkableSensors.every(x => x.checked);
                    x.indeterminate = !allChecked;
                    x.checked = allChecked;
                }                
            }); 

            const someChecked = this.devices.some(x => x.checked);
            if (someChecked) {
                const allChecked = this.devices.every(x => x.checked);
                this.allChecked = allChecked;
                this.indeterminate = !allChecked;
            }

            this.expanded = this.devices.filter(x => x.checkableSensors.some(p => p.checked));
        }); 
    }

    async getItems(filterObj?: ODataFilterObject) {
        this.loading = true;

        var request = '/api/devices?$expand=sensors';
        if (filterObj) {
            const query = this.queryBuilder(filterObj);
            request = `/api/devices?($filter=contains(Name, '${this.search}'))&$expand=sensors`;
            request = request + `(${query.split('?')[1]})`;
        }

        await this.authService.get<IRestResponseDto<DeviceCheckable[]>>(request, false).then((response) => {
            const result: DeviceCheckable[] = <DeviceCheckable[]><unknown>response.content;

            if (result.length) {
                this.devices = result.filter(x => x.sensors.length).map(device => {
                    return {
                        checked: false,
                        indeterminate: false,
                        ...device
                    };
                });
                this.devices.forEach(device => {
                    device.checkableSensors = device.sensors.map(sensor => {
                        return {
                            checked: false,
                            deviceId: device.id,
                            ...sensor
                        };
                    });
                });

                this.totalCount = response.count;
            }
        });        

        await this.authService.get<User>(`/api/users/${AuthStore.getUser().id}?$expand=ExportSettings($select=Id,DisplayName)&$select=ExportSettings`)
            .then((response) => {
                this.exportSettings = response.content.exportSettings;
        });

        this.loading = false;
    }

    async closeSaveExportDialog(mustReload?: boolean) {
        this.showSaveExportDialog = false;
        if (mustReload) {
            await this.authService.get<User>(`/api/users/${AuthStore.getUser().id}?$expand=ExportSettings($select=Id,DisplayName)&$select=ExportSettings`)
                .then((response) => {
                    this.exportSettings = response.content.exportSettings;
                });
        }
    }

    base64ToArrayBuffer(binaryString) {
        const binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    isValidDateSelection() {
        return new Date(this.startDate) < new Date(this.endDate);
    }

    getExportInput() {
        const devices = this.devices.filter(x => x.checkableSensors.some(p => p.checked));

        if (devices.length) {
            const exportInput: ExportInput = {
                start: new Date(this.startDate),
                end: new Date(this.endDate),
                languageCode: LanguageStore.getLanguage().languageCode,
                deviceSensorInputs: []
            };

            devices.forEach((device: DeviceCheckable) => {
                const deviceSensorInput: DeviceSensorInput = {
                    deviceId: device.id,
                    sensorIds: []
                };

                deviceSensorInput.sensorIds = device.checkableSensors.filter(x => x.checked).map(x => x.id.toString());
                exportInput.deviceSensorInputs.push(deviceSensorInput);
            });
            return exportInput;
        }
        return null;
    }

    deleteExportSetting(id: string) {
        this.swalConfirm(this.t('AreYouSureToDelete'), true, this.t('Delete')).then((result) => {
            if (result.value) {
                this.authService.delete(`/api/exportsettings/${id}`).then((response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.t('Successful'));
                        this.getItems();
                    }
                    else {
                        this.swalToast(2000, 'error', this.t('SomethingWrong'));
                    }
                });
            }
        });
    }

    handleFileDownload(response) {
        const result = response.content["data"];
        const res = this.base64ToArrayBuffer(result);

        const filename = "measurements-export.csv";
        const contentType = "application/octet-stream";
        try {
            const blob = new Blob([res], { type: contentType });

            //Check if user is using IE
            const ua = window.navigator.userAgent;
            const msie = ua.indexOf("MSIE ");

            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                window.navigator.msSaveBlob(blob, filename);
            }
            else  // If another browser, return 0
            {
                //Create a url to the blob
                const url = window.URL.createObjectURL(blob);
                const linkElement = document.createElement('a');
                linkElement.setAttribute('href', url);
                linkElement.setAttribute("download", filename);

                //Force a download
                const clickEvent = new MouseEvent("click", {
                    "view": window,
                    "bubbles": true,
                    "cancelable": false
                });
                linkElement.dispatchEvent(clickEvent);
            }

        } catch (ex) {
            console.log(ex);
        }
    }

    showSaveExport() {
        const exportInput = this.getExportInput();
        if (exportInput) {
            this.exportInput = exportInput;
            this.showSaveExportDialog = true;
        }       
    }

    createExport() {
        if (this.isValidDateSelection()) {     
            const exportInput = this.getExportInput();          
            if (exportInput) {
                this.authService.post(`/api/measurements/export`, exportInput).then((response) => this.handleFileDownload(response));
            }            
        }
    }
}

interface DeviceCheckable extends DeviceBasis {
    checkableSensors: SensorCheckable[];
    indeterminate: boolean;
    checked: boolean;
}

interface SensorCheckable extends Sensor {
    checked: boolean;
    deviceId: number;
}